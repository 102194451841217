{
  "all_services": "Alle Dienstleistungen",
  "badges": {
    "bestseller": "Bestseller",
    "fast_response": "Schnelle Reaktion",
    "full_capacity": "Volle Kapazität",
    "high_demand": "Hohe Nachfrage",
    "new_vendor": "Neuer Verkäufer",
    "responds_day": "Typischerweise antwortet er innerhalb eines Tages",
    "responds_few_days": "Typische Antwortzeit: 1-2 Tage",
    "responds_few_more_days": "Typische Antwortzeit: 3-4 Tage",
    "responds_hours": "Typische Antworten innerhalb von ein paar Stunden",
    "responds_week": "Typische Antwortzeit innerhalb einer Woche",
    "top_vendor": "Top-Anbieter"
  },
  "blocks": {
    "rating_columns": {
      "name_rated": "{name} bewertet"
    }
  },
  "blog": {
    "confirm": " Bestätigen Sie",
    "enter_your_email": "Ihre E-Mail eingeben",
    "next_recommended_read": "Nächste Leseempfehlung",
    "officeguru_can_store_my_data": "Abonnieren Sie unseren Newsletter und erhalten Sie Informationen aus erster Hand über spannende neue Updates und Funktionen",
    "others_have_read": "Andere haben gelesen",
    "sign_up_for_newsletter": "Anmeldung zum Newsletter"
  },
  "cart": {
    "cancel": "Abbrechen",
    "cart": "Wagen",
    "cart_changed_verify_content": "Der Inhalt Ihres Warenkorbs wurde geändert. Bitte überprüfen Sie den Inhalt Ihres Warenkorbs, bevor Sie einen Kauf tätigen.",
    "checkout": "Kasse",
    "delivery_fee": "Zustellgebühr",
    "did_you_know": "Wussten Sie das?",
    "discounts": {
      "add": "hinzufügen",
      "add_code_failed": "Hinzufügen von Code fehlgeschlagen",
      "cancel": "Abbrechen",
      "code": "Rabatt-Code",
      "code_placeholder": "z.B. SALE23",
      "discount_free_delivery": "Rabatt - Kostenlose Lieferung",
      "discount_n_percent_off": "Rabatt - {number}% Rabatt",
      "errors": {
        "error_og_discounts_distinct": "Der Rabattcode kann nur einmal verwendet werden",
        "error_og_discounts_invalid": "Der Rabattcode ist ungültig",
        "error_og_discounts_only_one_each_type": "Sie können von jeder Art nur einen Rabattcode verwenden"
      },
      "no_longer_available": "Rabatt(e) nicht mehr verfügbar",
      "no_longer_available_description": "Einige Rabatte für Ihre Bestellung sind nicht mehr verfügbar. Bitte entfernen Sie sie und versuchen Sie es erneut.",
      "remove_code_failed": "Entfernen von Code fehlgeschlagen",
      "unknown_error": "Unbekannter Fehler",
      "validating_cart_failed": "Die Validierung des Warenkorbs ist fehlgeschlagen."
    },
    "general_error": "Bei Ihrem Einkaufswagen ist ein Fehler aufgetreten. Wenn das Problem weiterhin besteht, kontaktieren Sie unseren Support.",
    "go_to_cart": "In den Warenkorb gehen",
    "go_to_marketplace": "Zum Marktplatz gehen",
    "officeguru_protection": "Officeguru Schutz",
    "pricing": "Preisgestaltung",
    "service_fee": "Dienstleistungsgebühr (1,79%)",
    "service_fee_percentage": "Dienstleistungsgebühr ({percentage}%)",
    "subtotal": "Zwischensumme",
    "total_in_dkk_incl_vat": "Gesamtbetrag in DKK (inkl. MwSt.)",
    "total_incl_vat": "Gesamt (inkl. MwSt.)",
    "vat": "MEHRWERTSTEUER (25%)",
    "you_can_buy_products_from_vendors": "Sie können Produkte von Verkäufern direkt über deren Profil kaufen. Gehen Sie einfach auf den Marktplatz und suchen Sie einen Anbieter, der Produkte verkauft.",
    "you_can_only_order_from_one_vendor": "Sie können jeweils nur Produkte von einem Anbieter bestellen. Ihr aktueller Warenkorb wird geleert, wenn Sie dieses Produkt hinzufügen."
  },
  "cart.add_to_cart": "Zum Warenkorb hinzufügen",
  "cart.add_to_cart_this_will_empty": "Zum Warenkorb hinzufügen? Dadurch wird der aktuelle Warenkorb geleert",
  "cart.your_cart_is_empty": "Ihr Warenkorb ist leer",
  "checkout": {
    "add_a_not_description": "Wenn Sie besondere Wünsche haben, schreiben Sie sie bitte hier",
    "add_a_note": "Eine Notiz hinzufügen",
    "address": "Adresse",
    "delivery": "Lieferung",
    "i_want_to_receive_marketing_content_and_newsletters": "Abonnieren Sie unseren Newsletter und erhalten Sie Informationen aus erster Hand über spannende neue Updates und Funktionen",
    "note_placeholder": "z.B. Sonderwünsche oder Allergene",
    "optional": "Optional",
    "order_overview": "Übersicht bestellen",
    "place_order": "Bestellung aufgeben",
    "preferred_delivery_date": "Bevorzugtes Lieferdatum",
    "the_desired_delivery_date_can_be_adjusted": "Das gewünschte Lieferdatum kann vom Verkäufer angepasst werden.",
    "you_will_receive_an_invoice": "Sie erhalten eine Rechnung, wenn Ihre Bestellung angenommen wurde."
  },
  "components": {
    "datepicker": {
      "select_a_date": "Wählen Sie ein Datum"
    },
    "footer": {
      "gazelle_winner": "Børsen Gazelle Gewinner seit 7 Jahren in Folge."
    },
    "login": "Anmeldung",
    "search": {
      "search": "Suchen"
    },
    "select_dropdown": {
      "select_an_option": "Wählen Sie eine Option"
    },
    "service_search": {
      "find_a_service": "Suchdienste",
      "no_results": "Keine Ergebnisse",
      "popular_services": "Beliebte Dienstleistungen",
      "search": "Suche",
      "zip": "Zip"
    },
    "signup": "sich einschreiben"
  },
  "customer": {
    "calendar": {
      "title": "Kalender"
    },
    "home": {
      "title": "Startseite"
    },
    "marketplace": "Marktplatz",
    "messages": "Nachrichten",
    "orders": {
      "title": "Bestellungen"
    },
    "partners_list": {
      "title": "Anbieter"
    },
    "settings": {
      "company_settings": "Einstellungen"
    },
    "tasks": {
      "title": "Aufgaben"
    }
  },
  "customer_signup_form": {
    "already_have_account": "Sie haben bereits ein Konto?",
    "button": {
      "submit": "Ein kostenloses Konto erstellen"
    },
    "contact_us": "Kontakt",
    "creating_account": "Konto erstellen",
    "customer": {
      "address": {
        "error_required": "Adresse ist erforderlich."
      },
      "email": {
        "error_email": "Die E-Mail muss eine gültige E-Mail sein",
        "error_required": "E-Mail ist erforderlich.",
        "error_unique": "Die E-Mail wird bereits genutzt."
      },
      "name": {
        "error_required": "Der Name des Kunden ist erforderlich.",
        "error_unique": "Kundenname ist bereits vergeben"
      },
      "person": {
        "error_required": "Name ist erforderlich."
      },
      "zip": {
        "error_between_numeric": "Die Postleitzahl muss zwischen 1050 und 9990 liegen.",
        "error_integer": "Der Zip muss nur aus Zahlen bestehen",
        "error_regex": "Die Postleitzahl ist ungültig für das gewählte Land",
        "error_required": "Zip ist erforderlich."
      }
    },
    "generic_error": "Leider konnten wir das Formular nicht absenden. Bitte versuchen Sie es in ein paar Sekunden erneut oder kontaktieren Sie uns für Hilfe.",
    "go_to_login": "Zur Anmeldung gehen",
    "label": {
      "address": "Adresse",
      "company": "Unternehmen",
      "country": "Land",
      "email": "E-Mail",
      "full_name": "Name",
      "i_have_read_and_accept": "Ich habe gelesen und akzeptiere die",
      "marketing_content_and_newsletter": "Abonnieren Sie unseren Newsletter und erhalten Sie Informationen aus erster Hand über spannende neue Updates und Funktionen",
      "password": "Passwort",
      "phone": "Rufnummer",
      "terms_at": "Ich habe die Nutzungsbedingungen von <a href=\"/en/terms\" target=\"blank\" rel=\"noopener\">gelesen und akzeptiere sie.</a>",
      "terms_of_service": "Bedingungen der Dienstleistung",
      "zip": "Zip"
    },
    "log_in": "Einloggen",
    "placeholder": {
      "address": "z.B. Bankgade 12",
      "company": "z.B. Fynske Bank",
      "email": "z.B. hans{'@'}fb.dk",
      "full_name": "z.B. Hans Petersen",
      "password": "Wir werden das geheim halten",
      "phone": "z.B. 45452323",
      "zip": "z.B. 1224"
    },
    "sign_up_it_is_free": "Registrieren Sie sich noch heute - es ist kostenlos",
    "start_getting_control": "Übernehmen Sie noch heute die Kontrolle über Ihren Arbeitsplatz.",
    "user": {
      "email": {
        "error_email": "Die E-Mail muss eine gültige E-Mail sein",
        "error_required": "E-Mail ist erforderlich.",
        "error_unique": "Die E-Mail wird bereits genutzt."
      },
      "password": {
        "error_required": "Ein Passwort ist erforderlich."
      }
    },
    "we_are_happy_you_are_part_of_og": "Erfolgreich! Wir freuen uns, Ihnen mitteilen zu können, dass Sie nun Teil von Officeguru sind",
    "you_can_now_log_in": "Sie können sich jetzt einloggen und die Vorteile der Plattform nutzen"
  },
  "forgot_password": "Haben Sie Ihr Passwort vergessen?",
  "go_to_app": "Zur App gehen",
  "httpErrors": {
    "base": {
      "contact_us": "Kontakt",
      "go_to_home": "Bring mich nach Hause",
      "go_to_marketplace": "Zum Marktplatz gehen",
      "go_to_products": "Zu den Produkten",
      "go_to_services": "Zu den Dienstleistungen",
      "go_to_vendor": "Zum Anbieter gehen",
      "or_if_you_need_help": "Oder wenn Sie Hilfe benötigen"
    },
    "fallthrough": {
      "title": "Wir <em>können die von Ihnen gesuchte Seite</em> nicht finden."
    },
    "generic_500": {
      "description": "Wir arbeiten daran, das Problem zu beheben. In der Zwischenzeit bringen wir Sie nach Hause.",
      "title": "Sorry, wir hatten einen <em>unerwarteten Fehler</em>"
    },
    "unknown_product": {
      "description_no_vendor": "Der Anbieter existiert nicht. Gehen Sie zu Dienstleistungen, um andere Anbieter zu finden, die das gewünschte Produkt verkaufen.",
      "description_vendor_exists": "Gehen Sie auf das Profil des Anbieters, um zu sehen, was er anbietet.",
      "title": "Wir <em>können das von Ihnen gesuchte Produkt nicht finden</em>."
    },
    "unknown_product_overview": {
      "description_no_vendor": "Der Anbieter existiert nicht. Gehen Sie zu Dienstleistungen, um andere Anbieter zu finden, die das gewünschte Produkt verkaufen.",
      "description_vendor_exists": "Der Anbieter verkauft keine Produkte. Gehen Sie zu Dienstleistungen, um andere Anbieter zu finden, die das von Ihnen benötigte Produkt verkaufen.",
      "title": "Wir <em>können die von Ihnen gesuchte Produktliste</em> nicht finden."
    },
    "unknown_service": {
      "description": "Unter Dienste finden Sie Dienste, die Ihren Bedürfnissen entsprechen.",
      "title": "Wir <em>können den von Ihnen gesuchten Dienst</em> nicht finden."
    },
    "unknown_vendor": {
      "description": "Suchen Sie auf dem Marktplatz nach anderen Anbietern, die Ihren Anforderungen entsprechen.",
      "title": "Wir <em>können den von Ihnen gesuchten Anbieter</em> nicht finden."
    }
  },
  "login": {
    "email": {
      "error_email": "Die E-Mail muss eine gültige E-Mail sein",
      "error_og_credentials": "E-Mail oder Passwort falsch.",
      "error_og_login_user_type_customer": "Diese E-Mail gehört nicht zu einem Kundenbenutzer",
      "error_required": "E-Mail ist erforderlich.",
      "error_unique": "Die E-Mail wird bereits genutzt."
    },
    "password": {
      "error_required": "Ein Passwort ist erforderlich."
    }
  },
  "officehub": {
    "close": "Schließen Sie",
    "managed_by": "Verwaltet von",
    "managed_by_tooltip": "Erläuterung der Unterkonten",
    "viewing_as_name": "Betrachten als {name}"
  },
  "pageError": {
    "404_description": "Wir haben an allen Ecken und Enden gesucht, aber wir konnten diese Seite nicht finden. Bringen wir Sie nach Hause.",
    "404_title": "Sorry, diese Seite existiert nicht",
    "500_description": "Wir arbeiten daran, das Problem zu beheben. In der Zwischenzeit bringen wir Sie nach Hause.",
    "500_title": "Entschuldigung, wir hatten einen unerwarteten Fehler",
    "take_me_home": "Bring mich nach Hause"
  },
  "product_page": {
    "add_to_cart": "Zum Warenkorb hinzufügen",
    "added_x_to_cart": "{name} wurde dem Warenkorb hinzugefügt.",
    "added_x_y_to_cart": "{name} {count}x wurde in den Warenkorb gelegt.",
    "price_excl_vat": "Preis (ohne MwSt.)"
  },
  "request_flow": {
    "add_more": "+ Tilfoj flere",
    "address_change_info": "The zip code has changed to {new_zip}. If this is incorrect, go back and change the address.",
    "address_change_info_title": "Address changed",
    "agree_to_vendor_terms": "I agree to the vendor's terms of service",
    "almost_there_sign_up": "Anmelden oder einloggen",
    "back": "zurück",
    "billed_after_completion": "Sie werden nach Abschluss der Aufgabe abgerechnet",
    "call_us": "Rufen Sie uns an",
    "call_us_for_help": "Rufen Sie uns an für Hilfe",
    "cancel": "Abbrechen",
    "choose_file": "Choose file",
    "choose_image": "Choose image",
    "choose_service": "Dienst auswählen",
    "choose_up_to_3": "Wählen Sie bis zu 3.",
    "confirm_and_send": "BESTÄTIGEN UND SENDEN",
    "confirm_your_details_before_we_send": "Bestätigen Sie Ihre Angaben, bevor wir sie an die Partner senden | Bestätigen Sie Ihre Angaben, bevor wir sie an die Partner senden",
    "continue": "Continue",
    "continue_anyway": "Continue anyway",
    "continue_with_request": "Continue with request",
    "customer_already_has_agreement": "You already have an agreement with this vendor for this service.",
    "days": {
      "friday": "Fr",
      "monday": "Mo",
      "saturday": "Sat",
      "sunday": "Sonne",
      "thursday": "Thu",
      "tuesday": "Di",
      "wednesday": "Mi"
    },
    "do_you_already_have_an_account": "Haben Sie bereits ein Konto?",
    "dont_have_account_already": "Sie haben noch kein Konto?",
    "dont_hesitate_to_call": "Zögern Sie nicht, uns anzurufen, wir werden unser Bestes tun, um Ihnen zu helfen.",
    "drag_file_here": "Datei(en) hierher ziehen oder zum Hochladen anklicken",
    "edit": "bearbeiten",
    "email": "E-Mail",
    "email_placeholder": "navn{'@'}firma.dk",
    "file_too_large": "File is too large. Max file size is 5MB.",
    "file_upload_error": "File upload failed. Please try again.",
    "files": "Files",
    "free_wo_strings": "Es ist kostenlos und unverbindlich",
    "get_an_offer": "Angebot einholen",
    "get_offer": "Angebot erhalten",
    "get_offer_for_service": "Erhalten Sie ein Angebot für {service}",
    "get_offer_from_vendor": "Erhalten Sie ein Angebot von {vendor}",
    "go_to_chat_support": "zum Chat-Support gehen",
    "help_us_understand_your_needs": "Help us understand your needs",
    "images": "Images",
    "invalid_address": "Address is invalid",
    "issue_on_our_end_working_on_it": "Bei der Bearbeitung Ihrer Anfrage ist bei uns ein Problem aufgetreten. Unser Team wurde benachrichtigt und wir arbeiten daran, das Problem zu beheben.",
    "leave": "Leave",
    "leave_description": "Are you sure you want to leave? Your changes will not be saved.",
    "locations_loading": " Standorte laden... ",
    "log_in": "Einloggen",
    "logging_in": "Einloggen",
    "login_and_get_offer": "Einloggen",
    "max_3_files_can_be_uploaded": "Maximum 3 files can be uploaded",
    "max_3_images_can_be_uploaded": "Maximum 3 images can be uploaded",
    "max_amount_of_vendors_selected": "You have selected the maximum amount of vendors",
    "max_vendors_reached_warning": "Sie können nicht mehr als {count} Lieferanten auswählen. Entfernen Sie einen, bevor Sie einen anderen auswählen.",
    "min_pax_count": "Min. Pax {count}",
    "n_additional_vendors": "Sie können unter {n} weitere Anbieter auswählen, um mehr Angebote zu erhalten.",
    "new_on_officeguru": "Neu auf Officeguru",
    "next": "Weiter",
    "no_more_vendors": "Du kannst keine weiteren Lieferanten auswählen.",
    "no_vendors_deliver_to_address": "Ach was, keine Anbieter liefern an {address}",
    "no_vendors_description": "Try to find a vendor at another address or contact us for help.",
    "no_vendors_found": "No vendors found",
    "now_drop": "Jetzt lassen Sie die Datei(en) hochladen",
    "offer_for_service": "Angebot für {service}",
    "optional": " (valgfrit)",
    "or": "oder",
    "password": "Passwort",
    "request_sent_description": "We will contact you soon to confirm your request.",
    "request_sent_title": "Your request has been sent!",
    "search_address_or_select": "Search address or select from list",
    "select_n_vendors": "Wählen Sie {count} Anbieter aus.",
    "select_service": "Dienst auswählen",
    "select_service_for_offer": "Wählen Sie den Dienst aus, für den Sie ein Angebot wünschen.",
    "select_service_placeholder": "Dienst auswählen",
    "select_up_to_3": "Select up to 3.",
    "select_vendor": "Anbieter auswählen",
    "select_vendors": "Lieferant(en) auswählen",
    "select_vendors_near": "Wählen Sie Anbieter in der Nähe",
    "selected": "Ausgewählte",
    "selected_n_vendors": "{count} vendors selected",
    "selected_vendors_description": "Select the vendors you want to receive offers from.",
    "selected_vendors_title": "Select vendors",
    "sending_request": "Bleiben Sie dran, wir senden Ihre Angebotsanfrage an unsere Partner",
    "sign_up": "sich einschreiben",
    "skip": "Skip",
    "skip_this_step": "Skip this step",
    "something_went_wrong": "Something went wrong",
    "something_went_wrong_description": "We couldn't send your request. Please try again.",
    "something_went_wrong_title": "Something went wrong",
    "sorry_something_went_wrong": "Entschuldigung, da ist etwas schief gelaufen!",
    "sure_you_want_to_cancel": "Angebotsanfrage schließen? Sie verlieren alle Informationen, die Sie eingegeben haben.",
    "tell_us_about_your_needs": "Tell us about your needs",
    "try_again": " Erneut versuchen",
    "trying_again": "Ich versuche es noch einmal...",
    "up_to_n_vendors": "Wählen Sie bis zu {n} Anbieter aus.",
    "upload_description": "You can upload up to 3 files or images below (max 5MB each)",
    "upload_error": "Upload error",
    "vendors_removed_info": "{count} Lieferant liefert nicht an Ihre Adresse. Sie können unten andere auswählen. | {count} Lieferanten liefern nicht an Ihre Adresse. Sie können unten andere auswählen.",
    "view_profile": "Profil ansehen",
    "we_might_have_vendors_that_deliver_to_other_location": "Möglicherweise haben wir Anbieter, die an Ihre anderen Standorte liefern - bitte wählen Sie einen Standort aus:",
    "we_will_ask_few_questions": "Beantworten Sie ein paar Fragen und erhalten Sie bis zu 3 unverbindliche Angebote.",
    "what_are_you_looking_for": "What are you looking for?",
    "what_vendors_would_you_like_offers_from": "Von welchen Partnern möchten Sie Angebote erhalten?",
    "which_location_do_you_need_service_for": "Which location do you need service for?",
    "which_location_to_deliver_to": "An welchen Ort möchten Sie Ihre Lieferung erhalten?",
    "which_vendors_in_address_would_you_like": "Von welchen Anbietern auf {address} möchten Sie Angebote erhalten?",
    "you_can_select_n_more_vendors": "You can select {count} more vendor | You can select {count} more vendors",
    "you_would_like_offers_from": "Sie möchten Angebote von"
  },
  "search": {
    "clear_all": "Alle löschen",
    "explore_marketplace": "Marketplace erkunden",
    "filters": {
      "all_services": "Alle Services",
      "can_add_one_vendor": "Sie können einen weiteren Lieferanten hinzufügen.",
      "can_add_three_vendors": "Sie können drei weitere Lieferanten hinzufügen.",
      "can_add_two_vendors": "Sie können zwei weitere Lieferanten hinzufügen.",
      "categories": "Kategorien",
      "close": "Schließen",
      "deliver_to_zip": "Zu PLZ liefern",
      "filter_by_tags": "Tags",
      "filters": "Filter",
      "minimum_pax": "Anzahl der Mahlzeiten",
      "minimum_pax_placeholder": "z.B. 5",
      "service": "Service",
      "show": "Anzeigen",
      "sort_by": "Sortiere nach",
      "vendors": "Lieferant",
      "your_zip": "Ihre Postleitzahl",
      "zip": "PLZ"
    },
    "find_anything": "Suche im Marketplace",
    "get_offer": "Angebot einholen",
    "load_more": "Weitere laden",
    "loading": "Laden",
    "marketplace": "Marktplatz",
    "max_vendors_reached_warning": "Sie können nicht mehr als {count} Lieferanten auswählen. Entfernen Sie einen Lieferanten, bevor Sie einen andere auswählen.",
    "min_count_pax": "Min {count} pax",
    "min_count_pax_short": "Anzahl der Mahlzeiten",
    "no_min_pax": "Kein Mindestpax",
    "no_price": "Kein Preis",
    "no_products_match_your_filters": "Keine Produkte entsprechen Ihren Filtern und Suchbegriffen.",
    "no_results_found": "Keine Ergebnisse gefunden",
    "no_services_match_your_filters": "Keine Dienste entsprechen Ihren Filtern und Suchbegriffen.",
    "no_vendors_match_your_filters": "Keine Anbieter entsprechen Ihren Filtern und Suchbegriffen.",
    "not_finding_what_you_are_looking_for": "Wir können dir helfen zu finden, was du suchst",
    "partners": "Anbieter",
    "price": "Preis",
    "price_from_per_pax": "Ab {price}/pax",
    "price_from_per_pax_fallback": "Ab {price}/pax",
    "price_range_per_pax": "{from} - {to}/pax",
    "products": "Produkte",
    "remove_filters": "Alle Filter löschen",
    "search": "Suche",
    "search_placeholder": "Suche im Marketplace",
    "search_results": "Suchergebnisse",
    "search_results_for_term": "Suchergebnisse für '{term}'",
    "services": "Dienstleistungen",
    "show_all_results": "Alle Ergebnisse anzeigen",
    "show_all_tags_count": "Alle anzeigen ({count})",
    "something_went_wrong": "Etwas ist schief gelaufen",
    "sorting": {
      "alphabetical": "Alphabetisch",
      "price_high_to_low": "Preis hoch nach niedrig",
      "price_low_to_high": "Preis niedrig nach hoch",
      "top_rated": "Bestbewertet"
    },
    "start_typing_to_search": "Fang an zu tippen, um zu suchen...",
    "tag_search_placeholder": "Suche nach Tags",
    "type": "Typ",
    "vendor_name": "Anbieter",
    "vendor_not_matching_filters": "Der ausgewählte Lieferant erfüllt nicht die Kriterien der angewendeten Filter.",
    "vendor_or_product_not_found": "Leider konnten wir das von Ihnen gesuchte Produkt oder den Anbieter nicht finden. Verwenden Sie die Suche, um auf dem Marktplatz zu finden, was Sie brauchen.",
    "vendor_or_product_not_found_header": "Artikel nicht gefunden",
    "vendors": "Anbieter",
    "we_can_help_finding_the_right_match_for_you": "Wir helfen Ihnen, den richtigen Lieferanten zu finden",
    "we_could_not_find_any_results": "Wir konnten keine Ergebnisse finden, die Ihren Filtern und Suchbegriffen entsprechen. Versuchen Sie eine andere Suche oder:",
    "zip_short": "PLZ"
  },
  "service_categories": {
    "cleaning": "Reinigung",
    "coffee": "Kaffee",
    "events_and_celebrations": "Veranstaltungen &amp; Feiern",
    "food_and_drinks": "Essen & Getränke",
    "fruit": "Obst",
    "handyman_services": "Handwerkliche Dienstleistungen",
    "office_and_wellness": "Büro &amp; Wellness"
  },
  "service_page": {
    "a_quick_chat_to_hear_more_about_your_company": "Ein kurzes Gespräch, um mehr über die Wünsche Ihres Unternehmens zu erfahren",
    "always_available": "Hohe Qualität und faire Preise",
    "availability_is_a_prerequisite_for_a_good_partnership": "Sie können ganz einfach Angebote einholen und Preise vergleichen. Alle Anbieter streben eine 5-Sterne-Bewertung an, um die beste Qualität für Sie zu gewährleisten.",
    "click_to_try_again": "Klicken Sie hier, um es erneut zu versuchen",
    "company": "Unternehmen",
    "contact": "Kontakt",
    "contact_form": "Kontakt-Formular",
    "contact_me_about_offers_today": "Kontaktieren Sie mich noch heute für Angebote",
    "contact_me_today": "Kontaktieren Sie mich heute",
    "count_ratings": "{count} Bewertungen",
    "create_account": "Konto erstellen",
    "create_your_account": "Ihr Konto erstellen",
    "deliver_to_zip": "Liefert an ZIP",
    "delivered_by": "Geliefert von",
    "delivers_to_you": "Liefert an Sie",
    "delivers_to_zip": "Liefert an {zip}",
    "description": "Beschreibung",
    "discover_all_vendors": "Alle Lieferanten entdecken",
    "email": "E-Mail",
    "error_loading_vendors": "Entschuldigung, es gab einen Fehler beim Laden der Anbieter.",
    "faq": "FAQ",
    "frequently_asked_questions": " Häufig gestellte Fragen ",
    "full_name": "Vollständiger Name",
    "get_a_cleaning_deal_that_is_easy_to_manage": "Die gesamte Kommunikation wird in einem gemeinsamen Posteingang gesammelt, in dem Sie und Ihre Kollegen sich abstimmen und den Überblick über alle Vereinbarungen behalten können.",
    "get_offers": "Angebot(e) einholen",
    "get_started": "Los geht's",
    "get_your_offer_now": "Holen Sie sich jetzt Ihr Angebot",
    "high_customer_satisfaction": "Hohe Kundenzufriedenheit",
    "i_accept_officeguru_stores_information": "Ich bin damit einverstanden, dass Officeguru meine Daten speichert und sich mit mir in Verbindung setzen darf.",
    "input_zip": "Eingabe ZIP",
    "learn_more": "Mehr erfahren",
    "marketplace": "Marktplatz",
    "min_pax_count": "Min. Pax {count}",
    "near_you": "in Ihrer Nähe",
    "no_ratings": "Noch keine Bewertungen",
    "no_vendors_can_deliver_to_your_zip": "Kein Anbieter kann diesen Dienst in die von Ihnen angegebene Postleitzahl liefern.",
    "not_ready_for_an_offer_yet": "Noch nicht bereit für ein Angebot? Vielleicht möchten Sie mehr hören? Kein Problem! Buchen Sie ein unverbindliches Gespräch mit einem unserer Spezialisten.",
    "officeguru_protection": "Officeguru Schutz",
    "officeguru_protection_description": "Mit Officeguru können Sie sich auf einen hervorragenden Service, hohe Qualität und wettbewerbsfähige Preise verlassen. Unsere geprüften Anbieter und benutzerfreundlichen Bedingungen machen den Wechsel zwischen ihnen mühelos. Wir bieten Versicherungsschutz für Schäden (die Entschädigung hängt von den Versicherungsgrenzen ab), und unser Customer Success Team steht Ihnen jederzeit zur Verfügung, um Sie zu unterstützen.",
    "officeguru_trains_cleaning_partners": "Alle unsere Anbieter haben die gleichen Bedingungen, und egal, ob Sie eine oder mehrere Dienstleistungen in Anspruch nehmen, Sie erhalten eine Sammelrechnung von Officeguru.",
    "or": "oder",
    "others_also_bought": "Andere kauften auch",
    "others_bought": "Andere kauften",
    "phone_number": "Rufnummer",
    "questions": "Fragen?",
    "read_more": "mehr lesen",
    "review_your_offer": "Überprüfen Sie Ihr Angebot",
    "reviews": "Bewertungen",
    "reviews_of_service": "Bewertungen von {service}",
    "reviews_of_service_x": "Bewertungen von {service}",
    "save_time_on_administration": "Sparen Sie Zeit bei der Verwaltung",
    "service_x_vendors": "{service} Lieferanten",
    "sign_up_get_offers": "Registrieren Sie sich und erhalten Sie Angebote von lokalen Anbietern",
    "something_went_wrong": "Etwas ist schief gelaufen",
    "sort_alphabetically": "Alphabetisch sortieren",
    "sort_by_top_rated": "Sortieren nach Top-Bewertung",
    "the_vendor_will_send_you_an_offer": "Der Verkäufer schickt Ihnen ein Angebot, das Ihren Bedürfnissen entspricht",
    "type_vendors": "{type} Anbieter",
    "valid_email": "Die E-Mail muss ein gültiges Format haben.",
    "vendor_tags": "Tags",
    "vendors": "Anbieter",
    "view": "Profil ansehen",
    "want_to_get_started_by_yourself": "Möchten Sie selbst anfangen?",
    "we_have_received_your_request": "Wir haben Ihre Anfrage erhalten",
    "we_will_contact_you_in_1_2_days": "Wir werden Sie innerhalb von 1-2 Tagen kontaktieren",
    "why_officeguru_is_right_for_you": "Warum Officeguru das Richtige für Sie ist",
    "you_will_be_contacted_by_specialists": "Sie werden von einem unserer Spezialisten kontaktiert"
  },
  "shared": {
    "loading_countries": "Länder werden geladen...",
    "n_current_of_n_total": "{current} von {total}",
    "showing_n_to_m_of_total_resource": "Anzeigen von {from} bis {to} von {total} {resource}"
  },
  "vendor_page": {
    "about": "Über",
    "account_owner": "Verantwortlich",
    "all_products": "Alle Produkte",
    "answer_from_name": "Antwort von {name}",
    "average_rating": "durchschnittliche Bewertung",
    "categories": "Kategorien",
    "click_here_to_refresh": "Klicken Sie hier zum Aktualisieren.",
    "close": "Schließen Sie",
    "count_ratings": "{count} Bewertungen",
    "delivers_to_you": "Liefert an Sie",
    "employees": "Mitarbeiter",
    "example_menu": "Beispiel-Menü",
    "faq": "FAQ",
    "faq_company_certifications": "Über welche Zertifizierungen verfügt Ihr Unternehmen?",
    "faq_company_consistency_and_quality": "Wie stellt Ihr Unternehmen eine gleichbleibende und hohe Qualität sicher?",
    "faq_company_sustainability": "Wie arbeitet Ihr Unternehmen mit der Nachhaltigkeit?",
    "faq_kind_of_customers": "Mit welcher Art von Kunden haben Sie zusammengearbeitet?",
    "faq_working_environment": "Wie sorgt Ihr Unternehmen für ein gutes Arbeitsumfeld?",
    "filter_by_item": "Filtern nach {item}",
    "filter_by_service": "Nach Dienstleistung filtern",
    "filtering_by_item": "Filtern nach {item}",
    "filtering_by_label": "Filtern nach {label}",
    "filters": "Filter",
    "guruscore_tooltip": "Der Guruscore wird auf der Grundlage von Kundenrezensionen auf dem Marktplatz berechnet und verwendet einen Bayes'schen Durchschnitt, um die Vergleichbarkeit zu erhöhen.",
    "latest_5_reviews": "Letzte 5 Bewertungen",
    "latest_count_reviews": "Neueste {count} Bewertungen",
    "latest_reviews": "Letzte Bewertungen",
    "load_count_more": "Laden Sie {count} mehr",
    "loading_of_products_failed": "Das Laden von Produkten ist fehlgeschlagen.",
    "marketplace": "Marktplatz",
    "minimum_order": "Mindestbestellmenge",
    "no_reviews_match_your_filters": "Keine Bewertungen entsprechen Ihren aktuellen Filtern",
    "on_date": "auf {date}",
    "operational_areas": "Operative Bereiche",
    "organic_badge": "Bio-Plakette",
    "organic_bronze": "30-60% biologisch",
    "organic_gold": "90-100% biologisch",
    "organic_silver": "60-90% biologisch",
    "overview": "Übersicht",
    "price": "Preis",
    "products": "Produkte",
    "products_description": "Finden Sie Produkte für Ihr Büro.",
    "rated": "überprüft",
    "rating_by_percentage": "Nach Bewertung filtern",
    "ratings": "Bewertungen",
    "review": "Überprüfung",
    "services": "Dienstleistungen",
    "services_provided": "Erbrachte Dienstleistungen",
    "smiley_report": "Smiley-Bericht",
    "star_rating_x": "{rating}-Sterne-Bewertung",
    "tags": "Tags",
    "terms_of_payment": "Zahlungsbedingungen",
    "terms_of_payment_description": "Dienstleistungen werden monatlich bezahlt und sind 8 Tage nach Rechnungsdatum fällig. Bei Officeguru gelten für alle Dienstleistungen die gleichen Bedingungen.",
    "vendors": "Anbieter",
    "verification_description": "Die Prüfplakette bedeutet, dass wir diesen Anbieter und seine Dienstleistungen geprüft haben und die Qualität sicherstellen können.",
    "verification_details": "Details zur Überprüfung",
    "verified": "Geprüft",
    "view": "Siehe",
    "view_all": "ALLE ANSICHTEN",
    "view_all_images": "Alle Bilder anzeigen",
    "view_menu": "Menü ansehen",
    "view_terms_of_payment": "Zahlungsbedingungen anzeigen",
    "would_hire_again": "Würde wieder einstellen",
    "years_in_business": "Jahre im Geschäft",
    "zip_ranges": "Postleitzahlenbereiche"
  },
  "vendor_signup_form": {
    "apply_for_free": "Bewerben Sie sich kostenlos und bauen Sie Ihr Unternehmen aus.",
    "button": {
      "submit": "Jetzt kostenlos loslegen"
    },
    "contact_us": "Kontakt",
    "generic_error": "Leider konnten wir das Formular nicht absenden. Bitte versuchen Sie es in ein paar Sekunden erneut oder kontaktieren Sie uns für Hilfe.",
    "get_new_customers_today": "Heute neue Kunden gewinnen",
    "label": {
      "company": "Unternehmen",
      "country": "Land",
      "email": "E-Mail",
      "marketing_content_and_newsletter": "Abonnieren Sie unseren Newsletter und erhalten Sie Informationen aus erster Hand über spannende neue Updates und Funktionen",
      "name": "Name",
      "phone": "Telefon",
      "what_services": "Welche Dienstleistungen bieten Sie an?"
    },
    "partner": {
      "description": {
        "error_required": "Eine Partnerbeschreibung ist erforderlich."
      },
      "name": {
        "error_required": "Name ist erforderlich.",
        "error_unique": "Der Name des Partners ist bereits vergeben"
      }
    },
    "placeholder": {
      "company": "z.B. Fynske Bank",
      "email": "z.B. hans{'@'}beispiel.de",
      "name": "z.B. Hans Petersen",
      "phone": "z.B. 45452323",
      "what_services": "z.B. Mittagessen, Reinigung"
    },
    "thanks_for_application": "Wir danken Ihnen! Wir haben Ihre Bewerbung erhalten",
    "user": {
      "email": {
        "error_email": "Die E-Mail muss eine gültige E-Mail sein",
        "error_required": "E-Mail ist erforderlich.",
        "error_unique": "Die E-Mail wird bereits genutzt."
      },
      "phone": {
        "error_required": "Telefonnummer ist erforderlich."
      }
    },
    "we_will_contact_you": "Wir werden Sie so bald wie möglich kontaktieren."
  }
}
